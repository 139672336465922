<template>
  <button
    v-if="isAuthenticated"
    class="channel_page__btn_favorite"
    @click="toggle"
  >
<!--    {{ type }}-->
    <Icon :icon="isFav ? 'pins/pin_favorite_fill' : 'pins/pin_favorite'" />
  </button>

  <modal-fav
    v-if="isModalVisible"
    :type="type"
    :content="content"
    @close="isModalVisible = false"
  />
</template>
<script setup>
import Icon from '@/components/common/icons/icon.vue';
import ModalFav from '@/components/ui/Elements/modalFav.vue';
import { alertService } from '@/services/alert';

const store = useFavoritesListStore();
store.fetchFavorites();

const isModalVisible = ref(false);

const props = defineProps({
  type: String,
  content: {
    type: Object,
    required: true,
  },
});

const isFav = shallowRef(false);

watchEffect(() => {
  isFav.value = store.isFavorite(props.content, props.type);
});

const { isAuthenticated } = storeToRefs(useUserStore());

async function openModal() {
  if (!isAuthenticated.value) {
    throw createError({
      message: 'Пожалуйста, нажмите кнопку «Войти» и введите свой логин и пароль, выданные Вашим оператором связи.',
    });
  }
  isModalVisible.value = true;
}

async function toggle() {
  if (!isAuthenticated.value) {
    throw createError({
      message: 'Пожалуйста, нажмите кнопку «Войти» и введите свой логин и пароль, выданные Вашим оператором связи.',
    });
  }

  const message = await store.toggleFavorite(props.content, props.type, isFav.value);
  alertService.success({ text: message, isIconCross: true, timeout: 4500 });
}
</script>
