<template>
  <div class="poster_info__pins flex">
    <div
      v-if="contentItem?.rating"
      class="poster_info__red"
    >
      {{ contentItem?.rating }}+
    </div>

    <div
      v-if="contentItem?.rating_imdb"
      class="poster_info__red flex"
    >
      <Icon
        icon="pins/pin_IMDb"
        class="mr12"
      />
      {{ contentItem?.rating_imdb }}
    </div>

    <div
      v-if="contentItem?.rating_kinopoisk"
      class="poster_info__red flex"
    >
      <Icon
        icon="pins/pin_kinopoisk"
        class="mr12"
      />
      {{ contentItem?.rating_kinopoisk }}
    </div>

    <div
      v-if="contentItem?.duration"
      class="poster_info__white"
    >
      {{ renderDuration(contentItem?.duration) }}
    </div>
    <div
      v-if="contentItem?.country?.length"
      class="poster_info__white"
    >
      {{ contentItem?.country?.join(', ') }}
    </div>
    <div
      v-if="contentItem?.year"
      class="poster_info__white"
    >
      {{ contentItem?.year }}
    </div>
  </div>
</template>

<script setup>
import Icon from '@/components/common/icons/icon.vue';

const props = defineProps({
  contentItem: {
    type: Object,
    default: () => ({}),
    required: true,
  },
});

function renderDuration(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const formattedHours = hours > 0 ? hours + ' ч. ' : '';
  const formattedMinutes = minutes > 0 ? minutes + ' мин.' : '';
  return formattedHours + formattedMinutes;
}
</script>
