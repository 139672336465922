<template>
  <VDialog
      :visible="true"
      size="small"
      overlay-color="rgba(17, 20, 21, 0.6)"
      @close="$emit('close')"
  >
    <template #content>
      <div style="padding: 15px;text-align: center">
        <span style="color: #fff;">{{ type }}</span>
        <div class="button-confirm flex">
          <button
              class="channel_card__btn_open_modal"
          >
            Добавить1
          </button>
          <button
              class="channel_card__btn_open_modal"
          >
            Добавить2
          </button>
        </div>
      </div>
    </template>
  </VDialog>
</template>
<script setup>
import VDialog from '@/components/common/VDialog.vue';

const props = defineProps({
  type: String,
  content: {
    type: Object,
    required: true,
  },
});

const question = ref({});

onMounted(() => {
  // switch (props.type) {
  //   case 'program':
  //     query.value.channel_id = props.content?.channel_id;
  //     query.value.program_time = props.content?.time_start;
  //     messageAdd = 'Телепередача добавлена в "Избранное"';
  //     messageDel = 'Телепередача удалена из "Избранного"';
  //     break;
  //   case 'film':
  //     query.film_id = props.content?.id;
  //     messageAdd = 'Видео добавлено в "Избранное"';
  //     messageDel = 'Видео удалено из "Избранного"';
  //     break;
  //   case 'channel':
  //     query.channel_id = props.content?.id;
  //     messageAdd = 'Канал добавлен в "Мои телеканалы"';
  //     messageDel = 'Канал удален из "Мои телеканалы"';
  // }

  // const query = {};
  // let messageAdd = '';
  // let messageDel = '';
  // switch (props.type) {
  //   case 'program':
  //     query.value.channel_id = props.content?.channel_id;
  //     query.value.program_time = props.content?.time_start;
  //     messageAdd = 'Телепередача добавлена в "Избранное"';
  //     messageDel = 'Телепередача удалена из "Избранного"';
  //     break;
  //   case 'film':
  //     query.film_id = props.content?.id;
  //     messageAdd = 'Видео добавлено в "Избранное"';
  //     messageDel = 'Видео удалено из "Избранного"';
  //     break;
  //   case 'channel':
  //     query.channel_id = props.content?.id;
  //     messageAdd = 'Канал добавлен в "Мои телеканалы"';
  //     messageDel = 'Канал удален из "Мои телеканалы"';
  // }
})

// if (isFav.value) {
//   await store.deleteFromFavorites(query);
//   alertService.success({ text: messageDel, isIconCross: true, timeout: 4500 });
// } else {
//   await store.addToFavorites(query);
//   alertService.success({ text: messageAdd, isIconCross: true, timeout: 4500 });
// }

</script>