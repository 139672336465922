<template>
  <div
    v-if="content?.contentId"
    class="poster_detail"
  >
    <div
      ref="descriptionRef"
      class="poster_detail__desc"
    >
      {{ content?.description || content?.desc }}
    </div>

    <div v-if="content?.have_seasons">
      <SeasonSwiper
        :content="content?.seasons"
        @set-season="setSeason"
      />
    </div>

    <ProgramSwiper
      :programs="episodes"
      local-storage-key="posterEpisodes"
    />

    <div
      v-if="seriesInfo.length"
      class="titleRow"
    >
      Выпуски
    </div>
    <ProgramSwiper
      :programs="seriesInfo"
      local-storage-key="posterSeries"
      :is-tv-serial="true"
      :is-episode="true"
    />

    <Credits :program="content" />
  </div>
</template>

<script setup>
import ProgramSwiper from '@/components/ui/Swipers/ProgramSwiper.vue';
import SeasonSwiper from '@/components/ui/Swipers/SeasonSwiper';
import Credits from '@/components/ui/Swipers/Credits.vue';
import uuid4 from 'uuid4';

const props = defineProps({
  content: {
    type: Object,
    required: true,
    default: () => ({}),
  },
});

const haveSeasons = shallowRef(false);
const episodes = shallowRef([]);
const id = uuid4();

const {
  data: _seriesInfo,
  fetch: fetchSeriesInfo,
  clean: cleanSeriesInfo,
} = useApiData('program/seriesinfo', {
  id,
  query: computed(() => ({
    cid: props.content?.channel_id,
    title_id: props.content?.title_id,
  })),
  watch: props.content,
  transform: d => transformObjectToArray(d.series),
});
const seriesInfo = computed(() => {
  return _seriesInfo.value || {};
});

watch(
  () => props.content,
  _content => {
    haveSeasons.value = false;
    episodes.value = [];

    if (!_content.contentId) {
      return;
    }
    if (_content.contentType === 'program') {
      fetchSeriesInfo();
      return;
    }

    if (_content.is_serial && _content.seasons.length) {
      haveSeasons.value = true;
      episodes.value = _content.seasons[0].episodes;
    }
  },
  { immediate: true }
);

const descriptionRef = ref(null);

onMounted(() => {
  if (descriptionRef.value) {
    observeElementDescription();
  }  
});

// Проследить за появлением элемента с описанием и прокрутить к нему, если он находится вне области просмотра
function observeElementDescription() {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  };

  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      const viewportHeight = window.innerHeight;
      const elementBottom = entry.boundingClientRect.top + entry.boundingClientRect.height;

      if (elementBottom > viewportHeight) {
        // Элемент появился, но находится вне области просмотра, можно прокрутить к нему
        entry.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      observer.disconnect();
    });
  }, options);

  observer.observe(descriptionRef.value);
}

onUnmounted(async () => {
  await cleanSeriesInfo();
});

function setSeason(season) {
  episodes.value = season.episodes;
}

function transformObjectToArray(inputObject) {
  let resultArray = [];
  let keys = Object.keys(inputObject);
  keys.forEach(key => resultArray.push(inputObject[key]));
  return resultArray;
}
</script>
